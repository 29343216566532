















import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import order from './components/order.vue'
import people from './components/people.vue'
import luru from './components/luru.vue'
@Component({
  name: 'channelQuery',
  components: {
    order,
    people,
    luru
  }
})
export default class AUser extends Vue {
  activeName='order'
  orderId = ''

  getRecord(data: any) {
    console.log(data);
    this.activeName = 'record'
    this.orderId = data
  }
  updata(data: any) {
    this.orderId = data
  }
}
