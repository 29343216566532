

























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
@Component({})
export default class realtimeOrder extends Vue {
  @Prop({ default: '' }) private orderId!: string

  @Watch('orderId')
  getOrderId(val: any) {
    console.log('vvvv', val);
    this.search.orderId = val
    this.searchTable()
  }

  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search = {
    orderId: '',
    sopType: '',
    sopInput:"", //录入信息
    policyHolder:"", //投保人名称
    status:"", // 0 假 1 真
    sort: null,
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  status = {
    0: "失败",
    1: "成功"
  }

  //补单
  budanInfo: any = {
    channelId: '',
    tradingCenterCode: '',
    findCode: '',
    orderCodeList: []
  }
  sure() {
    if(this.budanInfo.findCode === ''){
      this.$message.warning('请输入内容')
      return
    }
    let obj = {
      findCode: this.budanInfo.findCode,
    }
    this.loading = true
    api.post("J30206", obj).then((data) => {
      this.$message.success("操作成功");
      this.searchTable();
    }).finally(() => {
      this.loading = false
    });
  }

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    console.log('oooo', this.search.orderId);
    if(!this.search.orderId) {
      this.$emit('updata', '')
    }
    api
            .post("J30503", {
              ...this.search
            })
            .then((data) => {
              this.total = data.total;
              this.tableData = data.list;
            })
            .finally(() => {
              this.loading = false;
            });
  }

  config: any = {}
  getConf() {
    api.getConf().then((res) => {
      this.config = res
    })
  }


  activated() {
    this.searchTable();
    this.getConf()
  }
}
